import TimelineLite from 'gsap/TimelineLite'
import {Power2, Power4} from 'gsap'
import handleMeta from './handleMeta'

export const pageChanged = (element, isFirst, lastPath, pathname) => {
  handleMeta(pathname)
  if (isFirst)  {
    // 初回のアニメーション
    let toPosition = '0'
    if (pathname === '/')        toPosition = '0'
    if (pathname === '/about')   toPosition = '-120'
    if (pathname === '/contact') toPosition = '120'

    new TimelineLite()
      .to(element.current, 1, {ease: Power4.easeInOut, x: `${toPosition}%`, scale: 1})

  } else {
    // ページ遷移のアニメーション
    if (!element) return
    let fromPosition = '0'
    if (lastPath.current === '/')        fromPosition = '0'
    if (lastPath.current === '/about')   fromPosition = '-120'
    if (lastPath.current === '/contact') fromPosition = '120'
    let toPosition = '0'
    if (pathname === '/')        toPosition = '0'
    if (pathname === '/about')   toPosition = '-120'
    if (pathname === '/contact') toPosition = '120'

    new TimelineLite()
      .to(element.current, 1, {ease: Power4.easeIn, x: `${fromPosition/5}%`, scale: .2})
      .to(element.current, .8, {ease: Power2.easeInOut, x: `${toPosition/5}%`})
      .to(element.current, 1, {ease: Power4.easeInOut, x: `${toPosition}%`, scale: 1})

    lastPath.current = pathname
  }
}

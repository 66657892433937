import React, {Fragment, useRef} from 'react'
import {useTransition} from '../../historyHandler'
import {pageChanged} from '../animation'
import {firstEnter, enter, leave} from './animation'
import Content from './Content'

const About = () => {
  const isFirstEnterRef = useRef(true)
  const pageElement = useRef(null)
  const backSideElement = useRef(null)
  const isCurrentPageRef = useRef(false)
  const path = '/about'

  useTransition(({pathname, release, isFirst}) => {
    pageChanged(
      isFirst,
      isFirstEnterRef,
      pageElement,
      backSideElement,
      isCurrentPageRef,
      path,
      pathname,
      firstEnter,
      enter,
      leave,
      release,
    )
  })

  return (
    <Fragment>
      <div ref={backSideElement} className='page-backside page-backside--about'/>
      <div ref={pageElement} className='page-wrapper page-wrapper--about'>
        <div className='page-inner'>
          <Content/>
        </div>
      </div>
    </Fragment>
  )
}

export default About

import React, {Fragment, useEffect, useRef} from 'react'
import imgReactQueueRouter from './img/works_react-queue-router.png'
import imgFactfulBubbleChart from './img/works_factful-bubble-chart.png'
import {drawCanvas} from './animation'
import PortCircle from './PortCircle'
import Light from './Light'


const Content = () => {
  const port = useRef(null)
  useEffect(() => {
    window.addEventListener('resize', () => {
      port.current.style.height = `${window.innerHeight - 10}px`
    })
    drawCanvas()
  }, [])

  return (
    <Fragment>
      <div className='about-wrapper'>
        <div className='about-bg-wrapper' style={{width: `${window.innerWidth - 10}px`, height: `${window.innerHeight - 10}px`}}>
          <canvas className='about-bg' id='about-canvas'/>
        </div>
        <div className='about-content-wrapper'>
          <div className='about-port' ref={port} style={{height: `${window.innerHeight - 10}px`}}>
            <PortCircle/>
            <Light/>
            <p className='about-port__message'>
              I'm Masakatsu Tokita, <br className='only-sp'/>
              a web developer in Tokyo.<br/>
              This site, Tokita.dev, is my portfolio site.<br/>
              If you want to know more about me <br className='only-sp'/>
              or thankfully request a job,<br/>
              contact me through the Contact page.<br/>
              Or if you are just interested in me,<br/>
              follow my Twitter account :)<br/>
              <a href='https://twitter.com/Tokky0425' target='_blank' rel='noopener noreferrer' aria-label="jump to Twitter">@Tokky0425</a>
            </p>
          </div>

          <div className='about-prof'>
            <h2 className='about-ttl'>Profile</h2>
            <p className='about-prof__lead'>
              After spending 5 years on studying political science (specifically digging into the Cold War) in university in Tokyo, I was like "<b>All right... I'll dive into the world of web technology.</b>"<br/>
              Since then, I've been worked for a web design firm as a developer.<br/>
              My major interests therefore are mainly front end technologies, such as Single Page Application, CSS animation, and frontend performance optimization, while I love creating some random apps using server side languages.<br/>
            </p>
            <p className='about-prof__lead'>
              Besides web, I'm extremely happy when I see a movie (which is projected by a short focus projector) in my room sipping red wine.🍷
            </p>

            <div className='about-prof__info'>
              <div className='about-prof__info__cover-left'/>
              <div className='about-prof__info__cover-right'/>
              <div className='about-prof__info__row'>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__item'>Location</p>
                </div>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__desc'>
                    <span>Nakano, Tokyo</span>
                  </p>
                </div>
              </div>
              <div className='about-prof__info__row'>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__item'>Skills</p>
                </div>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__desc'>
                    <span>HTML, CSS</span>
                    <span>JavaScript, TypeScript, Python Go</span>
                    <span>React, Docker</span>
                    <span>Firebase</span>
                  </p>
                </div>
              </div>
              <div className='about-prof__info__row'>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__item'>Academic Background</p>
                </div>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__desc'>
                    <span>Waseda Univ.</span>
                    <span>Bachelor of political science</span>
                  </p>
                </div>
              </div>
              <div className='about-prof__info__row'>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__item'>Interests</p>
                </div>
                <div className='about-prof__info__col'>
                  <p className='about-prof__info__desc'>
                    <span>Football</span>
                    <span>Work out</span>
                    <span>Movies</span>
                    <span>Pomodoro</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='about-works'>
            <h2 className='about-ttl'>Works</h2>
            <div className='about-works__content'>
              <div className='about-works__item'>
                <h3 className='about-works__name'>
                  <span className='about-works__name__arrow'/>
                  React Queue Router
                </h3>
                <div className='about-works__desc'>
                  <p>
                    React Queue Router is an animation friendly router for React.
                    <br/>
                    The interface is similar to React Router but you can be more flexible about transition animation.
                  </p>
                  <p><a href='https://tokky0425.github.io/react-queue-router/' target='_blank' rel='noopener noreferrer'>https://tokky0425.github.io/react-queue-router/</a></p>
                </div>
                <div className='about-works__img'>
                  <img src={imgReactQueueRouter} alt='' draggable='false'/>
                </div>
              </div>
              <div className='about-works__item'>
                <h3 className='about-works__name'>
                  <span className='about-works__name__arrow'/>
                  Factful Bubble Chart
                </h3>
                <div className='about-works__desc'>
                  <p>
                    Factful Bubble Chart is a React library that illustrates a bubble chart with 5 dimensions. (X axis, Y axis, Time, Size, Group)
                    <br/>
                    The concept is strongly inspired by Hans Rosling's analysis method and his book titled "Factfulness".
                  </p>
                  <p><a href='https://tokky0425.github.io/factful-bubble-chart/' target='_blank' rel='noopener noreferrer'>https://tokky0425.github.io/factful-bubble-chart/</a></p>
                </div>
                <div className='about-works__img'>
                  <img src={imgFactfulBubbleChart} alt='' draggable='false'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Content

import TweenLite, {Power1} from 'gsap/TweenLite'
import TimelineLite from 'gsap/TimelineLite'

const setWillChange = () => {
  const pages = document.querySelectorAll('.location-handler, .page-backside, .page-wrapper')
  pages.forEach(page => {
    page.classList.add('will-change')
  })
}

const removeWillChange = () => {
  const pages = document.querySelectorAll('.location-handler, .page-backside, .page-wrapper')
  pages.forEach(page => {
    page.classList.remove('will-change')
  })
}

const handleLineAnimation = (action, openBtn) => {
  // ラインのアニメーションを削除
  const lineArr = Array.from(openBtn.querySelectorAll('span'))
  const className = 'is-animating'
  if (action === 'add') {
    lineArr.forEach(line => {
      line.classList.add(className)
    })
  } else if ('remove') {
    lineArr.forEach((line) => {
      line.classList.remove(className)
    })
  }
}

const stepShowing = menuList => {
  Array.from(menuList.querySelectorAll('li a')).forEach(target => {
    const txt = target.querySelector('.menu-list__txt')
    const line = target.querySelector('.menu-list__line')

    new TimelineLite()
      .to(txt, 0, {display: 'none', opacity: '0'})
      .to(target, 0, {opacity: 1, filter: 'blur(0)'})
      .to(target, 0, {
        display: 'block',
        onComplete: () => {
          setTimeout(() => {
            // メニューの表示
            new TimelineLite()
              .to(txt, 0, {display: 'block'})
              .to(line, 0, {transformOrigin: '50% 50%', height: '1px', x: '-50%', y: '-50%', scaleX: '0'})
              .to(line, .3, {x: '-50%', y: '-50%', scaleX: '1'})
              .to(line, .3, {x: '-50%', y: '-50%', height: '100%'})
              .to(line, 0, {transformOrigin:'100% 50%'})
              .to(txt, 0, {opacity: '1'})
              .to(line, .3, {scaleX: '0'})
          }, 50)
        }
      })
  })
}

const stepHiding = menuList => {
  Array.from(menuList.querySelectorAll('li a')).forEach(target => {
    const txt = target.querySelector('.menu-list__txt')
    const line = target.querySelector('.menu-list__line')

    new TimelineLite()
      .to(target, 1, {opacity: 0, filter: 'blur(10px)'})
      .to(line, 0, {transformOrigin: '50% 50%', height: '1px', x: '-50%', y: '-50%', scaleX: '0'})
      .to(txt, 0, {display: 'none', opacity: '0'})
  })
}

/**
 * メニューのボタンを表示する
 */
export const activateMenu = ({wrapper, bg, openBtn}) => {
  const isPC = window.innerWidth >= 768
  handleLineAnimation('remove', openBtn)

  new TimelineLite()
    .to(bg, 0, {
      opacity: '1'
    })
    .to(wrapper, 0, {
      display: 'block',
      bottom: `${isPC ? '45' : '42'}px`,
      width: '0',
      height: '0',
      borderRadius: '50%',
      opacity: '1',
      rotation: '-720',
      x: '-50%',
    })
    .to(wrapper, .6, {
      ease: Power1.easeOut,
      bottom: '20px',
      width: `${isPC ? '50' : '45'}px`,
      height: `${isPC ? '50' : '45'}px`,
      scale: '1',
      rotation: '0',
    })
    .to(openBtn, 0, {
      scale: '1',
      opacity: '1',
      onComplete: () => {
        removeWillChange()
        openBtn.disabled = '' // アクティブに変更
        openBtn.classList.add('is-hover-active')
        handleLineAnimation('add', openBtn)
      }
    })
}

/**
 * メニューを開く
 */
export const openMenu = ({wrapper, bg, openBtn, closeBtn, menuList}) => {
  setWillChange()

  new TimelineLite()
    .to(wrapper, .4, {ease: Power1.easeIn, scale: '1.5', boxShadow: 'box-shadow: 0px 2px 6px 1px #787878'})
    .to(wrapper, .4, {ease: Power1.easeIn, scale: '1', boxShadow: 'box-shadow: 0px 2px 6px -1px #787878'})
    .to(wrapper, .3, {ease: Power1.easeIn, boxShadow: '0px 2px 6px -12px #787878'})
  new TimelineLite()
    .to(wrapper, .8, {ease: Power1.easeIn, rotation: '720'})
    .to(wrapper, 0, {rotation: '0'})
    .to(wrapper, .3, {width: '1px', borderRadius: '0%'})
    .to(wrapper, .5, {height: '100%', bottom: '0%'})
    .to(wrapper, .3, {width: '100%',
      onComplete: () => {
        TweenLite.to(closeBtn, .3, {scale: '1'})
        stepShowing(menuList)
      }
    })
  TweenLite.to(bg, .5, {opacity: '.65', delay: .8})
  TweenLite.to(openBtn, .4, {
    scale: '0',
    onComplete: () => {
      handleLineAnimation('remove', openBtn)
    }
  })
}

/**
 * メニューを閉じる
 */
export const closeMenu = ({wrapper, bg, openBtn, closeBtn, menuList}) => {
  const isPC = window.innerWidth >= 768
  stepHiding(menuList)

  TweenLite.to(wrapper, .5, {bottom: `${isPC ? '45' : '42'}px`, height: '1px', delay: .9,
    onComplete: () => {
      TweenLite.to(bg, .2, {opacity: '1'})
      new TimelineLite()
        .to(wrapper, .2, {width: '1px', borderRadius: '50%'})
        .to(wrapper, 0, {rotation: '720', height: '1px'})
        .to(wrapper, .6, {bottom: '20px', width: `${isPC ? '50' : '45'}px`, height: `${isPC ? '50px' : '45px'}`, rotation: '0', boxShadow: '0px 2px 6px -1px #787878'})
        .to(openBtn, .4, { // 開くボタンを拡大
          scale: '1',
          onComplete: () => {
            removeWillChange()
            handleLineAnimation('add', openBtn)
          }
        })
    }
  })
  TweenLite.to(closeBtn, .3, {scale: '0'})
}

/**
 * メニューが開いている状態からの遷移
 */
export const closeMenuForTransition = ({wrapper, openBtn, closeBtn, menuList}) => {
  stepHiding(menuList)

  TweenLite.to(wrapper, .5, {
    ease: Power1.easeIn,
    opacity: '0',
    onComplete: () => {
      TweenLite.to(wrapper, 0, {display: 'none'})
      handleLineAnimation('remove', openBtn)
    }
  })
  TweenLite.to(closeBtn, .3, {scale: '0'})
}

/**
 * メニューが開いていない状態からの遷移（ブラウザバックで発生）
 */
export const hideMenuForTransition = ({wrapper, openBtn}) => {
  TweenLite.to(wrapper, .3, {
    ease: Power1.easeIn,
    scale: '.1',
    rotation: '-720',
    onComplete: () => {
      TweenLite.to(wrapper, 0, {display: 'none', rotation: '0'})
      handleLineAnimation('remove', openBtn)
    }
  })

  // 開くボタンを縮小
  TweenLite.to(openBtn, .3, {scale: '0'})
}

import React, {useRef, Fragment, useEffect} from 'react'
import imfFlashLight from './img/flashlight.svg'
import Draggable from 'react-draggable'

const Light = () => {
  const wrapper = useRef(null)
  const light = useRef(null)

  return (
    <Fragment>
      <Draggable
        onStart={() => {
          wrapper.current.style.zIndex = '2'
          light.current.style.display = 'block'
        }}
        onStop={() => {
          wrapper.current.style.zIndex = '4'
          light.current.style.display = 'none'
        }}
      >
        <div className='about-port__light-wrapper' ref={wrapper}>
          <div className='about-port__light' ref={light}/>
          <img src={imfFlashLight} alt='' draggable='false'/>
          <span/>
        </div>
      </Draggable>
    </Fragment>
  )
}

export default Light

import TweenLite, {Power3} from 'gsap/TweenLite'
import {enterFlip, leaveFlip} from '../animation'
import Particle from './particle'

export const firstEnter = (page, backSide, isFirst, release) => {
  const callback = () => {
    const topPageInner = document.querySelector('.top-page-inner')
    const topLogo = document.querySelector('.top-logo')
    const topContentSection = document.querySelector('.top-content-section')
    const topName = document.querySelector('.top-name span')
    const topDescriptionArr = Array.from(document.querySelectorAll('.top-description span'))
    TweenLite.to(topLogo, 1.8, {
      ease: Power3.easeInOut,
      y: '0%',
      opacity: '1',
    })

    TweenLite.to(topContentSection, 1.2, {
      ease: Power3.easeInOut,
      opacity: '1',
      onComplete: () => {
        // name and description
        TweenLite.to(topName, .6, {y: '0%'})
        let delay = .1
        topDescriptionArr.forEach((item, i) => {
          TweenLite.to(item, .6, {y: '0%', delay: delay * (i + 1)})
        })

        topPageInner.classList.add('is-scrollable')
        // TODO: ここでメニューをactivateする
        release()
      }
    })
  }
  enterFlip(page, backSide, isFirst, callback)

  // パーティクル
  const particle = new Particle('top-canvas', 'top-canvas-sibling')
  particle.init()
}

export const enter = (page, backSide, isFirst, release) => {
  const topLogo = document.querySelector('.top-logo')
  const topName = document.querySelector('.top-name span')
  const topDescriptionArr = Array.from(document.querySelectorAll('.top-description span'))
  const itemArr = [topLogo, topName, ...topDescriptionArr]
  const delay = .08
  const callback = () => {
    itemArr.forEach((item, i) => {
      TweenLite.to(item, .4, {y: '0', opacity: '1', delay: delay * i})
    })
    // TODO: ここでメニューをactivateする
    release()
  }

  enterFlip(page, backSide, isFirst, callback)
}

export const leave = (page, backSide, isFirst) => {
  const topLogo = document.querySelector('.top-logo')
  const topName = document.querySelector('.top-name span')
  const topDescriptionArr = Array.from(document.querySelectorAll('.top-description span'))
  const itemArr = [topLogo, topName, ...topDescriptionArr]
  const delay = .08
  itemArr.forEach((item, i) => {
    TweenLite.to(item, .4, {y: '-20px', opacity: '0', delay: delay * i})
  })
  const callback = () => {
    itemArr.forEach((item, i) => {
      TweenLite.to(item, 0, {y: '20px', opacity: '0'})
    })
  }

  leaveFlip(page, backSide, isFirst, callback)
}

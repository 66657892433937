import React, {useState, useRef, useContext, useEffect} from 'react'
import {createBrowserHistory} from 'history'
import {MenuContext} from './components/Menu/Menu'

export const history = createBrowserHistory()
export const HistoryContext = React.createContext()
export const HistoryContextProvider = HistoryContext.Provider

export const forceUpdate = () => {
  history.replace(window.location.path)
}

const useUpdate = (historyStore) => {
  const [count, setCount] = useState(0)
  const countRef = useRef(0)
  const isFirst = useRef(true)
  if (isFirst.current) {
    history.listen(() => {
      historyStore.push(history.location.pathname)
      // コンポーネントを再描画するためにカウントを上げる
      countRef.current++
      setCount(countRef.current) // TODO: setCountに渡すのがcount+1ではだめで、Refで参照しないと動かなかった
    })
    isFirst.current = false
  }
}

export const useTransition = (animationFunc) => {
  const {setMenuStatus} = useContext(MenuContext)
  const {isFirst, localHistoryStore, setLocalHistoryStore, isTransiting, setIsTransiting} = useContext(HistoryContext)
  // releaseを呼び出すことでキューを減らし、次にスタックされていたキューを扱う
  const release = () => {
    setMenuStatus('activate') // メニューを出現
    isFirst.current = false
    localHistoryStore.shift() // キューを減らす
    setIsTransiting(false)
    setLocalHistoryStore(localHistoryStore)
  }

  useEffect(() => {
    if (localHistoryStore.length === 0 || isTransiting) return
    setIsTransiting(true)
    const pathname = localHistoryStore[0]
    animationFunc({pathname, release, isFirst: isFirst.current})
  })
}

export const HistoryHandler = ({children}) => {
  const historyStore = useRef([])
  // 初回のトランジションの判定を行うためのフラグ
  // forceUpdateと組み合わせて、ページランディング時のアニメーションのハンドリングするために使う
  const isFirst = useRef(true)
  const [isTransiting, setIsTransiting] = useState(false)
  const [localHistoryStore, setLocalHistoryStore] = useState(historyStore.current)
  useUpdate(historyStore.current)

  return (
    <HistoryContextProvider value={{
      isFirst,
      localHistoryStore,
      setLocalHistoryStore,
      isTransiting,
      setIsTransiting,
    }}>
      {children}
    </HistoryContextProvider>
  )
}

export const Link = ({children, to}) => {
  const transition = event => {
    event.preventDefault()
    history.push({
      pathname: event.currentTarget.pathname,
      search: event.currentTarget.search,
    })
  }

  return <a href={to} onClick={transition}>{children}</a>
}

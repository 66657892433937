import {enterFlip, leaveFlip} from '../animation'

export const firstEnter = (page, backSide, isFirst, release) => {
  const callback = () => {
    const contactTextarea = document.querySelector('.contact-text-area')
    const twitter = document.querySelector('.contact-service__item--twitter')
    const linkedin = document.querySelector('.contact-service__item--linkedin')
    const line = document.querySelector('.contact-form-line')
    const inputArr = Array.from(document.querySelectorAll('.contact-form'))
    const btn = document.querySelector('.contact-btn')

    contactTextarea.classList.add('is-visible')
    twitter.classList.add('is-visible')
    setTimeout(() => { linkedin.classList.add('is-visible') }, 400)
    setTimeout(() => { line.classList.add('is-visible') }, 800)
    inputArr.forEach((input, index) => {
      setTimeout(() => {
        input.classList.add('is-visible')
      }, 1200 + 200 * index)
    })
    setTimeout(() => { btn.classList.add('is-visible') }, 2400)
    const func = () => {
      // TODO: ここでメニューをactivateする
      release()
      btn.removeEventListener('transitionend', func)
    }
    btn.addEventListener('transitionend', func)
  }
  enterFlip(page, backSide, isFirst, callback)
}

export const enter = (page, backSide, isFirst, release) => {
  const callback = () => {
    // TODO: ここでメニューをactivateする
    release()
  }
  enterFlip(page, backSide, isFirst, callback)
}

export const leave = (page, backSide, isFirst) => {
  leaveFlip(page, backSide, isFirst)
}

import React, {Fragment, useContext, useState} from 'react'
import logoTwitter from './img/logo_twitter.svg'
import logoLinkedin from './img/logo_linkedin.svg'
import {ContactModalContext, ripple} from '../../components/ContactModal/ContactModal'

const emailValidationReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const isValidEmail = email => emailValidationReg.test(email)

const Content = () => {
  const {confirmBtn, form, state, setState} = useContext(ContactModalContext)
  const [errorStore, setErrorStore] = useState([])

  const handleBlank = (target, name) => {
    let errorArr = []
    let errorStoreCopy = errorStore.slice()

    // 空ならエラー
    if (!target.value) {
      if (!errorStore.includes(`${name}-blank`)) {
        errorArr.push(`${name}-blank`)
      }
    } else {
      errorStoreCopy = errorStoreCopy.filter(error => error !== `${name}-blank`)
    }

    // emailの場合
    // 無効なアドレスならエラー
    if (name === 'email') {
      if (!isValidEmail(target.value)) {
        if (!errorStore.includes('email-invalid')) {
          errorArr.push('email-invalid')
        }
      } else {
        errorStoreCopy = errorStoreCopy.filter(error => error !== 'email-invalid')
      }
    }

    return [...errorStoreCopy, ...errorArr]
  }

  const name = form.current && form.current.name
  const email = form.current && form.current.email
  const message = form.current && form.current.message

  return (
    <Fragment>
      <div className='contact-content-wrapper'>
        <div className='contact-text-area'>
          <p><span>Contact me via...</span></p>
          <ul>
            <li><span>Twitter</span></li>
            <li><span>LinkedIn</span></li>
            <li><span>or, the form below</span></li>
          </ul>
        </div>
        <div className='contact-service'>
          <div className='contact-service__item contact-service__item--twitter'>
            <a href='https://twitter.com/Tokky0425' target='_blank' rel='noopener noreferrer' aria-label="jump to Twitter">
              <img src={logoTwitter} alt='' draggable='false'/>
            </a>
          </div>
          <div className='contact-service__item contact-service__item--linkedin'>
            <a href='https://www.linkedin.com/in/masakatsu-tokita-15a782164/' target='_blank' rel='noopener noreferrer' aria-label="jump to LinkedIn">
              <img src={logoLinkedin} alt='' draggable='false'/>
            </a>
          </div>
        </div>
        <div className='contact-form-line'/>
        <form ref={form}>
          <div className='contact-form contact-form--name'>
            <input
              type='text'
              placeholder='Name'
              name='name'
              onChange={e => { setErrorStore(handleBlank(e.target, 'name')) }}
            />
            <div className='contact-form__complete-ele'/>
            {errorStore.includes('name-blank') && <p className='contact-form__error-message'>Name cannot be blank.</p>}
          </div>
          <div className='contact-form contact-form--email'>
            <input
              type='email'
              placeholder='Email address'
              name='email'
              onChange={e => { setErrorStore(handleBlank(e.target, 'email')) }}
            />
            <div className='contact-form__complete-ele'/>
            {errorStore.includes('email-blank') && <p className='contact-form__error-message'>Email address cannot be blank.</p>}
            {errorStore.includes('email-invalid') && <p className='contact-form__error-message'>Email address is invalid.</p>}
          </div>
          <div className='contact-form contact-form--textarea'>
            <textarea
              placeholder='Lorem ipsum dolor sit amet, consectetur adipisicing elit.'
              onChange={e => { setErrorStore(handleBlank(e.target, 'message')) }}
              name='message'
            />
            <div className='contact-form__complete-ele'>Thank you for your contact.</div>
            {errorStore.includes('message-blank') && <p className='contact-form__error-message'>Message cannot be blank.</p>}
          </div>
        </form>
        <div className='contact-btn'>
          <button
            ref={confirmBtn}
            onClick={e => {
            ripple(e)
              const errorArr = [
                ...handleBlank(name, 'name'),
                ...handleBlank(email, 'email'),
                ...handleBlank(message, 'message'),
              ]
              setErrorStore(errorArr)
            if (errorArr.length === 0) {
              setState('confirm')
            }
          }}>
            Confirm
            <span className='ripple'/>
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default Content

import TweenLite from 'gsap/TweenLite'
import TimelineLite from 'gsap/TimelineLite'

export const pageChanged = (
  isFirst,
  isFirstEnterRef,
  pageElement,
  backSideElement,
  isCurrentPageRef,
  path,
  pathname,
  firstEnter,
  enter,
  leave,
  release,
) => {
  if (pathname === path) {
    isCurrentPageRef.current = true
    if (isFirstEnterRef.current) {
      isFirstEnterRef.current = false
      firstEnter(pageElement.current, backSideElement.current, isFirst, release)
    } else {
      enter(pageElement.current, backSideElement.current, isFirst, release)
    }
  } else {
    if (!isCurrentPageRef.current) return
    leave(pageElement.current, backSideElement.current, isFirst)
    isCurrentPageRef.current = false
  }
}

/**
 * ページに入っていく際のフリップアニメーション
 * @param page     対象DOM
 * @param backSide 対象の背面DOM
 * @param isFirst  サイト訪問時の初回フリップ判定
 * @param callback コールバック
 */
export const enterFlip = (page, backSide, isFirst, callback = () => {}) => {
  const DURATION = 1
  const ENTER_DELAY = isFirst ? 0 : 1.8
  TweenLite.to(backSide, DURATION, {rotationY: '180', delay: ENTER_DELAY})
  new TimelineLite()
    .to(page, 0, {rotationY: '-180'}) // CSS指定だとなぜか初回だけ逆回転になってしまうため、0秒でリセットする
    .to(page, DURATION, {
      rotationY: '0',
      delay: ENTER_DELAY,
      onComplete: () => callback(),
    })
}

/**
 * ページを離れていく際のフリップアニメーション
 * @param page     対象DOM
 * @param backSide 対象の背面DOM
 * @param isFirst  サイト訪問時の初回フリップ判定
 * @param callback コールバック
 */
export const leaveFlip = (page, backSide, isFirst, callback = () => {}) => {
  const DURATION = 1
  const LEAVE_DELAY = isFirst ? 0 : .8
  TweenLite.to(backSide, DURATION, {rotationY: '0', delay: LEAVE_DELAY})
  TweenLite.to(page, DURATION, {
    rotationY: '-180',
    delay: LEAVE_DELAY,
    onComplete: () => callback(),
  })
}

import React, {Fragment} from 'react'
import imgLogo from './img/logo.svg'

const Content = () => {
  return (
    <Fragment>
      <canvas id='top-canvas'/>
      <div className='top-content-wrapper' id='top-canvas-sibling'>
        <div className='top-logo'>
          <img src={imgLogo} alt='Tokita.dev' draggable='false'/>
        </div>
        <div className='top-content-section'>
          <div className='top-profile'>
            <div className='top-profile__circle-backside'/>
            <div className='top-profile__circle'>
              <div className='top-profile__img'/>
            </div>
          </div>
          <p className='top-name'>
            <span>Masakatsu Tokita</span>
          </p>
          <p className='top-description'>
            <span>A Tokyo based web engineer</span>
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default Content

import TimelineLite from 'gsap/TimelineLite'
import {Power1, Power2, Power3, Power4} from 'gsap'
import {interpolate} from 'flubber'
import * as d3 from 'd3'

const showCover = () => {
  return new Promise(resolve => {
    const target = document.querySelector('#first-view .cover')
    new TimelineLite().to(target, 2, {
      ease: Power4.easeInOut,
      scale: '1',
      left: '0',
    })
    .to(target, 1.2, {
      ease: Power4.easeInOut,
      height: '100%',
      top: '0%',
      onComplete: () => { resolve() },
    })
  })
}

const showLogo = () => {
  return new Promise(resolve => {
    const svgWrapper = document.querySelector('.first-view-logo-svg-wrapper')
    const svgArr = Array.from(svgWrapper.querySelectorAll('svg'))
    const svgLength = svgArr.length

    svgArr.forEach(svg => {
      svg.style.willChange = 'filter, opacity'
    })

    svgArr.forEach((svg, index) => {
      const logoShowingDuration = 1.6
      new TimelineLite().to(svg, logoShowingDuration, {ease: Power3.easeInOut, y: 0, opacity: 1})
      new TimelineLite()
      .to(svg, 0, {
        transition: 'filter 1s', // ここの秒数は無視されるっぽい
        delay: logoShowingDuration + index * 0.12,
        onComplete: () => {
          new TimelineLite().to(svg, 1.2, {
            filter: 'blur(10px)',
            onComplete: () => {
              new TimelineLite().to(svg, 1, {
                opacity: 0,
                onComplete: () => {
                  svg.style.display = 'none'
                  svg.style.willChange = 'auto'
                  if (index === svgLength - 1) {
                    resolve()
                  }
                }
              })
            }
          })
        }
      })
    })
  })
}

const showObject = () => {
  return new Promise(resolve => {
    // オブジェクト
    const object = document.querySelector('.object')
    const objectSvg = object.querySelector('svg')
    const triangle = 'M 80,0 149.282,120 10.718,120 z'
    const circle = 'M 80 160 C 35.81722001353651 160 0 124.18277998646349 0 80 C 0 35.81722001353651 35.81722001353651 0 80 0 C 124.18277998646349 0 160 35.81722001353651 160 80 C 160 124.18277998646349 124.18277998646349 160 80 160'
    const interpolator1 = interpolate(triangle, circle)

    // ライン
    const line = document.querySelector('.line')
    const lineSvg = line.querySelector('svg')
    const linePath = 'M0,19 375,19 375,20 0,20'
    const wavePath = 'M8.52651283e-14,19 C34.7463346,8.3117481 63.7687946,2.96762215 87.0673801,2.96762215 C122.015258,2.96762215 187.5,24.4761891 261.795599,24.4761891 C290.673767,24.4761891 328.408567,22.6507927 375,19 L375,20 C315.710641,29.9113812 278.770703,34.8670717 264.180187,34.8670717 C239.059078,34.8670717 137.228072,10.8749411 84.3480032,10.8749411 C49.0946242,10.8749411 20.9786232,13.9166274 8.52651283e-14,20'
    const interpolator2 = interpolate(linePath, wavePath)

    // アニメーション開始
    new TimelineLite()
    .to(objectSvg, 0, {scale: 0, rotation: -270})
    .to(objectSvg, 1.2, {
      ease: Power1.easeOut,
      scale: 1,
      rotation: 0,
      onStart: () => {
        object.classList.add('is-spinning')

        // ライン出現
        new TimelineLite().to(lineSvg, 1.6, {
          ease: Power1.easeInOut,
          scale: 1,
        })
      },
      onComplete: () => {
        new TimelineLite().to(objectSvg, 1, {
          ease: Power1.easeInOut,
          rotation: 180,
          delay: .8,
          onStart: () => {
            // オブジェクト
            d3.select('#objectSet path')
              .transition()
              .duration(1200)
              .attrTween('d', () => interpolator1)
            // ライン
            d3.select('#line path')
              .transition()
              .duration(1600)
              .attrTween('d', () => interpolator2)
            new TimelineLite()
            .to(line, .8, {opacity: 0, delay: .8})
            resolve()
          }
        })
      }
    })
  })
}

const showBalls = () => {
  return new Promise(resolve => {
    const object = document.querySelector('.object')
    const balls = document.querySelector('.balls')
    // 次のアニメーションに向けてパフォーママンスを向上させる
    object.style.willChange = 'transform, border-radius'
    balls.style.willChange = 'transform'

    new TimelineLite()
    .to(balls, 0, {
      top: window.innerHeight / 2 - balls.clientHeight / 2,
      left: window.innerWidth / 2 - balls.clientWidth / 2,
    })

    const ballArr = Array.from(balls.querySelectorAll('.balls .ball'))
    let delay = .05
    ballArr.forEach((ball, index) => {
      new TimelineLite()
      .to(ball, 0, {rotation: -360, scale: 0})
      .to(ball, 1.8, {
        ease: Power3.easeOut,
        rotation: 0,
        scale: 1,
        delay: delay * index,
        onComplete: () => {
          resolve()
        }
      })
    })
  })
}

const transformCard = () => {
  return new Promise(resolve => {
    const object = document.querySelector('.object')
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const objectWidth = object.clientWidth
    const objectHeight = object.clientHeight
    const shorter = objectWidth < objectHeight ? objectWidth : objectHeight
    const windowDiagonal = Math.sqrt(windowWidth*windowWidth + windowHeight*windowHeight)
    const scale = windowDiagonal / shorter

    const balls = document.querySelector('.balls')
    const ballArr = Array.from(balls.querySelectorAll('.balls .ball'))
    // object（円）を四角に
    new TimelineLite()
      .to(object, 0, {backgroundColor: '#fff', borderRadius: '50%', rotation: 45, transformOriginZ: '100px'}) // transformOriginZはsafariなどでの表示バグ対策
      .to(object, 1, {ease: Power3.easeIn, borderRadius: 0, scale: scale,
        onComplete: () => {
          object.style.willChange = 'auto'
        }
      })
    new TimelineLite()
      .to(object, .8, {ease: Power3.easeIn, rotation: 180, delay: .2})

    const offset = 80
    ballArr.forEach((ball, index) => {
      const item = ball.querySelector('.item')
      const itemInner = ball.querySelector('.item-inner')
      ball.style.transition = 'transform 1.5s ease-in-out'
      new TimelineLite()
      .to(itemInner, 1, {
        ease: Power3.easeIn,
        borderRadius: 0,
        onComplete: () => {
          ball.style.transform = `translate3d(0,0,${offset - index*20}px)`
          new TimelineLite()
          .to(item, 1.5, {top: '50%', left: '50%', x: '-50%', y: '-50&', delay: .5,
            onComplete: () => { resolve() }
          })
        }
      })
    })

    new TimelineLite()
    .to(balls, 3, {
      ease: Power1.easeInOut,
      rotationX: 43,
      rotationY: 406,
      rotationZ: 7,
      x: 0,
      y: 0,
    })
  })
}

const reverseCard = () => {
  return new Promise(resolve => {
    const balls = document.querySelector('.balls')
    const ballArr = Array.from(balls.querySelectorAll('.balls .ball'))
    const offset = 240

    ballArr.forEach((ball, index) => {
      let scale = index === 3 || index === 4 || index === 5 ? 1: 0.01 // 0にするとsafariなどでなぜか挙動がおかしくなる
      ball.style.transition = 'transform 1s ease-in-out'
      ball.style.transform = `translate3d(0,0,${offset - index*60}px) scale(${scale})`
      setTimeout(() => {
        if (index === 3 || index === 4 || index === 5) {
          ball.style.transition = 'transform .6s ease-in-out'
          ball.style.transform = `translate3d(0,0,0) scale(1)`
          if (index === 3) { ball.style.zIndex = 3 }
          if (index === 4) { ball.style.zIndex = 2 }
          if (index === 5) {
            ball.style.zIndex = 1
            setTimeout(() => { resolve() }, 600)
          }
        } else {
          ball.style.display = 'none' // scale(0.01)となっているものを消す（safariなどのバグ対策）
        }
      }, 1000)
    })

    new TimelineLite()
    .to(balls, 1.6, {
      ease: Power1.easeInOut,
      rotationX: 0,
      rotationY: 0,
      rotationZ: 0,
      x: 0,
      y: 0,
      onComplete: () => {
        balls.style.willChange = 'auto'
      }
    })

    ballArr.forEach(ball => {
      const item = ball.querySelector('.item')
      new TimelineLite()
      .to(item, 1.6, {
        ease: Power1.easeInOut,
        top: '50%',
        left: '50%',
        x: '-50%',
        y: '-50%',
      })
    })
  })
}

const spreadCard = () => {
  return new Promise(resolve => {
    const balls = document.querySelector('.balls')
    const ballArr = Array.from(balls.querySelectorAll('.balls .ball'))
    new TimelineLite()
    .to(balls, 0, {
      top: '50%',
      left: '50%',
      x: '-50%',
      y: '-50%',
      onComplete: () => {
        new TimelineLite()
        .to(balls, 1, {
          ease: Power2.easeInOut,
          width: '20%',
          height: '20%',
        })
        ballArr.forEach((ball, index) => {
          const item = ball.querySelector('.item')
          let left = 0
          if (index === 3) { left = '-120%' }
          if (index === 5) { left = '120%' }
          new TimelineLite()
          .to(item, 1, {
            ease: Power2.easeInOut,
            width: '100%',
            height: '100%',
          })
          .to(ball, 1, {
            ease: Power2.easeInOut,
            left: left,
            onComplete: () => { resolve() }
          })
        })
      }
    })

    const pageBackside = Array.from(document.querySelectorAll('.page-backside'))
    const pageWrapper = Array.from(document.querySelectorAll('.page-wrapper'))
    pageBackside.forEach(target => { target.style.willChange = 'transform' })
    pageWrapper.forEach(target => { target.style.willChange = 'transform' })
  })
}

const removeFirstView = () => {
  return new Promise(resolve => {
    const firstView = document.getElementById('first-view')
    document.body.removeChild(firstView)
    resolve()
  })
}

const firstAnimation = async (callback) => {
  await showCover()
  await showLogo()
  await showObject()
  await showBalls()
  await transformCard()
  await reverseCard()
  await spreadCard()
  await removeFirstView()
  callback()
}

export default firstAnimation

import {enterFlip, leaveFlip} from '../animation'

export const firstEnter = (page, backSide, isFirst, release) => {
  const callback = () => {
    const portCircleBackside = document.querySelector('.about-port__circle__backside')
    const portCircleFront = document.querySelector('.about-port__circle__front')
    const portCircle = document.querySelector('.about-port__circle')
    const transition = 'all 1s cubic-bezier(.66,.03,0,1.57)'
    portCircleBackside.style.transition = transition
    portCircleFront.style.transition = transition
    portCircleBackside.classList.add('is-flipped')
    portCircleFront.classList.add('is-flipped')
    portCircle.classList.add('is-flashed')

    const portMessage = document.querySelector('.about-port__message')
    portMessage.classList.add('is-visible')

    setFlashLightPosition()
    setTimeout(showBalloon, 1000)

    // TODO: ここでメニューをactivateする
    setTimeout(release, 1000)
  }
  enterFlip(page, backSide, isFirst, callback)
}

export const enter = (page, backSide, isFirst, release) => {
  const callback = () => {
    // TODO: ここでメニューをactivateする
    release()
  }
  enterFlip(page, backSide, isFirst, callback)
}

export const leave = (page, backSide, isFirst) => {
  leaveFlip(page, backSide, isFirst)
}

class Triangle {
  constructor(canvas, ctx, heightRatio, speed, initialXRatio = 0) {
    this.canvas = canvas
    this.ctx = ctx
    this.heightRatio = heightRatio
    this.speed = speed
    this.initialXRatio = initialXRatio
    this.translateX = 0
    this.setSize()
    this.handleResize()
  }
  setSize() {
    this.canvasWidth = this.canvas.width
    this.canvasHeight = this.canvas.height
    this.initialX = this.canvasWidth * this.initialXRatio
    this.triangleHeight = this.canvasHeight * this.heightRatio
    this.triangleWidth = this.triangleHeight * 1.45
    this.point1 = {x: -this.triangleWidth / 2 + this.initialX, y: this.triangleHeight} // 下の点
    this.point2 = {x: -this.triangleWidth + this.initialX, y: 0} // 下の点
    this.point3 = {x: 0 + this.initialX, y: 0} // 下の点
    this.breakWidth = this.triangleWidth + this.canvasWidth - this.initialX
    const windowWidth = window.innerWidth
    if (1440 <= windowWidth) {
      // 大
      this.speedAdjustet = 3
    } else if (1000 <= windowWidth && windowWidth < 1400) {
      // 中
      this.speedAdjustet = 2
    } else {
      // 小
      this.speedAdjustet = 1
    }
  }
  handleResize() {
    window.addEventListener('resize', () => {
      this.setSize()
    })
  }
  draw() {
    this.ctx.beginPath()
    this.ctx.moveTo(this.point1.x + this.translateX, this.point1.y) //最初の点の場所
    this.ctx.lineTo(this.point2.x + this.translateX, this.point2.y) //2番目の点の場所
    this.ctx.lineTo(this.point3.x + this.translateX, this.point3.y) //3番目の点の場所
    this.ctx.closePath()
    this.ctx.fillStyle = '#F3AA68'
    this.ctx.fill()
    if (this.translateX >= this.breakWidth) {
      this.translateX =  - this.initialX
    } else {
      this.translateX += this.speed * this.speedAdjustet
    }
  }
}

class TriangleCanvas {
  constructor(canvas) {
    this.canvas = canvas
    this.canvasWidth = this.canvas.width
    this.canvasHeight = this.canvas.height
    this.ctx = canvas.getContext('2d')
    this.ctx.globalCompositeOperation = 'multiply'
    this.triangle1 = new Triangle(canvas, this.ctx,.35, 3, .6)
    this.triangle2 = new Triangle(canvas, this.ctx,.65, 2)
    this.triangle3 = new Triangle(canvas, this.ctx,.95, .8, .45)
    this.handleResize()
  }
  handleResize() {
    window.addEventListener('resize', () => {
      this.ctx.globalCompositeOperation = 'multiply'
      this.canvasWidth = this.canvas.width
      this.canvasHeight = this.canvas.height
    })
  }
  update() {
    this.ctx.clearRect(0,0, this.canvasWidth, this.canvasHeight)
    this.triangle1.draw()
    this.triangle2.draw()
    this.triangle3.draw()
  }
}

export const drawCanvas = () => {
  const canvas = document.getElementById('about-canvas')
  const parentEle = canvas.parentElement
  const SetCanvasSize = () => {
    parentEle.style.width = `${window.innerWidth - 10}px`
    parentEle.style.height = `${window.innerHeight - 10}px`
    const canvasWidth = parentEle.clientWidth
    const canvasHeight = parentEle.clientHeight
    canvas.width = canvasWidth
    canvas.height = canvasHeight
  }
  SetCanvasSize()
  window.addEventListener('resize', SetCanvasSize)

  const triangleCanvas = new TriangleCanvas(canvas)
  const tick = () => {
    triangleCanvas.update()
    requestAnimationFrame(tick)
  }
  tick()
}

const setFlashLightPosition = () => {
  const func = () => {
    const circleEle = document.querySelector('.about-port__circle')
    const wrapper = document.querySelector('.about-port__light-wrapper')
    const circleEleTop = circleEle.getBoundingClientRect().top
    const circleEleLeft = circleEle.getBoundingClientRect().left
    const circleEleWidth = circleEle.clientWidth
    const circleEleHeight = circleEle.clientHeight
    const wrapperWidth = wrapper.clientWidth
    const wrapperHeight = wrapper.clientHeight
    wrapper.style.top = `${circleEleTop + circleEleHeight/2 - wrapperHeight/2}px`
    wrapper.style.left = `${circleEleLeft + circleEleWidth/2 - wrapperWidth/2}px`
  }
  func()
  window.addEventListener('resize', func)
}

const showBalloon = () => {
  const isSP = window.innerWidth <= 768
  if (!isSP) return
  const ele = document.querySelector('.about-port__circle__balloon')
  ele.classList.add('is-balloon-active-sp')
}

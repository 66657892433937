import React, {Fragment, createContext, useContext, useRef, useEffect, useState} from 'react'
import TweenLite from 'gsap/TweenLite'
import axios from 'axios'

export const ContactModalContext = createContext()
export const ContactModalContextProvider = ContactModalContext.Provider

export const useContactForm = () => {
  const form = useRef(null)
  const confirmBtn = useRef(null)
  const cancelBtn = useRef(null)
  const sendBtn = useRef(null)
  const [state, setState] = useState('cancel')

  return [
    form,
    confirmBtn,
    sendBtn,
    cancelBtn,
    state,
    setState,
  ]
}

export const ripple = (e) => {
  const {target} = e
  const rect = e.target.getBoundingClientRect()
  const rippleItem = target.querySelector('.ripple')
  if (!rippleItem) return

  const relativeX = e.clientX - rect.left
  const relativeY = e.clientY - rect.top

  rippleItem.style.left = `${relativeX}px`
  rippleItem.style.top = `${relativeY}px`
  rippleItem.classList.add('is-animating')
  setTimeout(() => {
    if (rippleItem.classList.contains('is-animating')) {
      rippleItem.classList.remove('is-animating')
    }
  }, 800)
}

const escapeHtml = txt => {
  if(typeof txt !== 'string') return txt
  return txt.replace(/[&'`"<>]/g, match =>({
    '&': '&amp;',
    "'": '&#x27;',
    '`': '&#x60;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;',
  }[match]))
}

const parseLineBreak = txt => {
  return escapeHtml(txt).replace(/\n/g, '<br/>')
}

const showItem = (item, duration) => {
  TweenLite.to(item, 0, {
    display: 'block',
    onComplete: () => {
      TweenLite.to(item, duration, {
        opacity: 1,
        filter: 'blur(0)',
        scale: 1,
        x: '-50%',
        y: '-50%',
      })
    }
  })
}

const hideItem = (item, duration) => {
  TweenLite.to(item, duration, {
    opacity: 0,
    filter: 'blur(10px)',
    scale: .95,
    x: '-50%',
    y: '-50%',
    onComplete: () => { TweenLite.to(item, 0, { display: 'none' }) }
  })
}

const sendMessage = (data) => {
  return new Promise((resolve, reject) => {
    const postPath = process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/tokita-dev/us-central1/sendmail'
      : 'https://us-central1-tokita-dev.cloudfunctions.net/sendmail'
    axios.post(postPath, data)
      .then(() => {
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

const ContactModal = () => {
  const {form, confirmBtn, cancelBtn, sendBtn, state, setState} = useContext(ContactModalContext)
  const wrapper = useRef(null)
  const content = useRef(null)
  const loader = useRef(null)
  const completeMessage = useRef(null)
  const errorMessage = useRef(null)
  const name = form.current && form.current.name
  const email = form.current && form.current.email
  const message = form.current && form.current.message

  useEffect( () => {
    if (!wrapper.current) return
    const duration = .4
    if (state === 'confirm') {
      TweenLite.to(wrapper.current, duration, { opacity: 1, display: 'block' })
      showItem(content.current, duration)
      hideItem(loader.current, duration)
      hideItem(completeMessage.current, duration)
      hideItem(errorMessage.current, duration)

    } else if (state === 'send') {
      hideItem(content.current, duration)
      setTimeout(async () => {
        showItem(loader.current, duration)
        const data = {
          name: name.value,
          email: email.value,
          message: message.value,
        }
        try {
          await sendMessage(data) // メッセージ送信
          setState('send-complete')
        } catch (e) {
          console.error(e.code, e.message, e.details)
          setState('send-failed')
        }
      }, duration * 1000)

    } else if (state === 'send-complete') {
      hideItem(content.current, duration)
      hideItem(loader.current, duration)
      setTimeout(() => {
        showItem(completeMessage.current, duration)
        setTimeout(() => {
          TweenLite.to(confirmBtn.current.parentNode, duration, {
            opacity: 0,
            filter: 'blur(10px)',
            onComplete: () => {
              TweenLite.to(confirmBtn.current.parentNode, duration, {
                height: 0,
                marginTop: 0,
              })
            }
          })
          name.classList.add('is-complete')
          email.classList.add('is-complete')
          message.classList.add('is-complete')
          const completeEleArr = Array.from(form.current.querySelectorAll('.contact-form__complete-ele'))
          completeEleArr.forEach(completeEle => {
            TweenLite.to(completeEle, 0, {
              display: 'flex',
              onComplete: () => {
                TweenLite.to(completeEle, duration, {
                  opacity: 1,
                  filter: 'blur(0)',
                })
              }
            })
          })
          setState('cancel')
        }, duration * 1000 + 1500)
      }, duration * 1000)
    } else if (state === 'send-failed') {
      hideItem(content.current, duration)
      hideItem(loader.current, duration)
      setTimeout(() => {
        showItem(errorMessage.current, duration)
      }, duration * 1000)
    } else if (state === 'cancel') {
      TweenLite.to(wrapper.current, duration, {
        opacity: 0,
        onComplete: () => { TweenLite.to(wrapper.current, 0, { display: 'none', delay: duration }) }
      })
      hideItem(content.current, duration)
      hideItem(loader.current, duration)
      hideItem(completeMessage.current, duration)
      hideItem(errorMessage.current, duration)
    }
  }, [state])

  return (
    <Fragment>
      <div className='contact-confirm__overlay' ref={wrapper}>
        <div className='contact-modal-loader' ref={loader}>
          <div className='contact-modal-loader__circle'/>
        </div>
        <div className='contact-modal-complete-message' ref={completeMessage}>
          <p>Message has been sent.</p>
        </div>
        <div className='contact-modal-error-message' ref={errorMessage}>
          <p>Error occurred! Try again.</p>
          <button
            className='contact-modal-error-message__btn'
            onClick={() => { setState('cancel') }}
          >
            <span>OK</span>
          </button>
        </div>
        <div className='contact-confirm' ref={content}>
          <div className='contact-confirm__scroll-wrapper'>
            <div className='contact-confirm__inner'>
              <p className='contact-confirm__txt'>You are going to send the message below.</p>
              <div className='contact-confirm__content'>
                <dl>
                  <dt>Name</dt>
                  <dd>{name && name.value}</dd>
                </dl>
                <dl>
                  <dt>Email Address</dt>
                  <dd>{email && email.value}</dd>
                </dl>
                <dl>
                  <dt>Message</dt>
                  <dd dangerouslySetInnerHTML={{__html : message && parseLineBreak(message.value)}}/>
                </dl>
              </div>
              <div className='contact-confirm__input-txt-wrapper'/>
              <div className='confirm-btn-wrapper'>
                <div className='confirm-btn confirm-btn--cancel'>
                  <button
                    ref={cancelBtn}
                    onClick={e => {
                      ripple(e)
                      setState('cancel')
                    }}>
                    Cancel
                    <span className='ripple'/>
                  </button>
                </div>
                <div className='confirm-btn confirm-btn--send'>
                  <button
                    ref={sendBtn}
                    onClick={e => {
                      ripple(e)
                      setState('send')
                    }}>
                    Send
                    <span className='ripple'/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ContactModal

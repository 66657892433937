import React, {createContext, useRef, useEffect, useState, useContext, Fragment} from 'react'
import {Link, useTransition} from '../../historyHandler'
import {activateMenu, openMenu, closeMenu, closeMenuForTransition, hideMenuForTransition} from './animation'
import imgMenuCloseBtn from './img/menu-close-btn.svg'

export const MenuContext = createContext()
export const MenuContextProvider = MenuContext.Provider

export const useMenuStatus = () => {
  const [menuStatus, setMenuStatus] = useState('')
  return [menuStatus, setMenuStatus]
}

const useMenuAnimation = (elements, menuStatus) => {
  const isMenuOpen = useRef(false)

  useEffect(() => {
    switch (menuStatus) {
      case 'activate':
        isMenuOpen.current = false
        activateMenu(elements)
        break
      case 'open':
        isMenuOpen.current = true
        openMenu(elements)
        break
      case 'close':
        isMenuOpen.current = false
        closeMenu(elements)
        break
      case 'closeForTransition':
        if (isMenuOpen.current) {
          closeMenuForTransition(elements)
        } else {
          hideMenuForTransition(elements)
        }
        break
      default :
        break
    }
  }, [menuStatus])
}

const Menu = () => {
  const {menuStatus, setMenuStatus} = useContext(MenuContext)
  const wrapper = useRef(null)
  const bg = useRef(null)
  const openBtn = useRef(null)
  const closeBtn = useRef(null)
  const menuList = useRef(null)
  const elements = {
    wrapper: wrapper.current,
    bg: bg.current,
    openBtn: openBtn.current,
    closeBtn: closeBtn.current,
    menuList: menuList.current,
  }

  useTransition(() => { setMenuStatus('closeForTransition') })
  useMenuAnimation(elements, menuStatus)

  return (
    <Fragment>
      <button onClick={() => {
        // 連続クリック防止
        openBtn.current.disabled = 'disabled'
        setMenuStatus('open')
        setTimeout(() => { openBtn.current.classList.remove('is-hover-active') }, 1000)
      }} ref={openBtn} className='menu-open-btn' aria-label='Open Menu'>
        <span/>
        <span/>
        <span/>
      </button>
      <div className='open-btn-hover-item'/>
      <button onClick={() => {
        // 連続クリック防止
        closeBtn.current.disabled = 'disabled'
        setTimeout(() => {
          closeBtn.current.disabled = ''
          openBtn.current.disabled = '' // アクティブに変更
          openBtn.current.classList.add('is-hover-active')
        }, 1000)
        setMenuStatus('close')
      }} ref={closeBtn} className='menu-close-btn' aria-label='Close Menu'>
        <img src={imgMenuCloseBtn} alt=''/>
      </button>
      <div className='menu-wrapper' ref={wrapper}>
        <div className='menu-bg' ref={bg}/>
        <ul ref={menuList} className='menu-list'>
          <li>
            <Link to='/contact'>
              <span className='menu-list__txt'>Contact</span>
              <span className='menu-list__line'/>
            </Link>
          </li>
          <li>
            <Link to='/'>
              <span className='menu-list__txt'>Top</span>
              <span className='menu-list__line'/>
            </Link>
          </li>
          <li>
            <Link to='/about'>
              <span className='menu-list__txt'>About</span>
              <span className='menu-list__line'/>
            </Link>
          </li>
        </ul>
      </div>
    </Fragment>
  )
}

export default Menu

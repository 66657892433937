import React from 'react'
import ReactDOM from 'react-dom'
import './styles/style.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Stats from 'stats.js'

ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.register()

if (process.env.NODE_ENV === 'development') {
  const stats = new Stats()
  stats.showPanel(0)
  document.body.appendChild(stats.dom)
  const animate = () => {
    stats.begin()
    stats.end()
    requestAnimationFrame(animate)
  }

  requestAnimationFrame(animate)
}

import React, {useEffect} from 'react'
import {HistoryHandler,forceUpdate} from './historyHandler'
import LocationHandler from './components/LocationHandler/LocationHandler'
import ContactModal, {ContactModalContextProvider, useContactForm} from './components/ContactModal/ContactModal'
import Menu, {MenuContextProvider, useMenuStatus} from './components/Menu/Menu'
import Top from './pages/top/Top'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import firstAnimation from './firstAnimation'

const App = () => {
  useEffect(() => {
    firstAnimation(() => {
      forceUpdate()
    })
  }, [])

  const [
    form,
    confirmBtn,
    sendBtn,
    cancelBtn,
    state,
    setState,
  ] = useContactForm()

  const [
    menuStatus,
    setMenuStatus
  ] = useMenuStatus()

  return (
    <HistoryHandler>
      <div className={'app-wrapper'}>
        <ContactModalContextProvider value={{
          form,
          confirmBtn,
          sendBtn,
          cancelBtn,
          state,
          setState,
        }}>
          <MenuContextProvider value={{
            menuStatus,
            setMenuStatus,
          }}>
            <LocationHandler>
              <Top/>
              <About/>
              <Contact/>
            </LocationHandler>
            <Menu/>
            <ContactModal/>
          </MenuContextProvider>
        </ContactModalContextProvider>
      </div>
    </HistoryHandler>
  )
}

export default App

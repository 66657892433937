import React, {useRef} from 'react'
import {useTransition} from '../../historyHandler'
import {pageChanged} from './animation'

const LocationHandler = ({children}) => {
  const element = useRef(null)
  let lastPath = useRef(window.location.pathname)
  useTransition(({pathname, isFirst}) => pageChanged(element, isFirst, lastPath, pathname))

  return (
    <div ref={element} className='location-handler'>
      {children}
    </div>
  )
}

export default LocationHandler

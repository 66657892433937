const handleMeta = path => {
  const metaTitle = document.querySelector('title')
  const metaThemeColor = document.querySelector('meta[name="theme-color"]')

  let title = ''
  let themeColor

  switch (path) {
    case '/about':
      title = 'About'
      themeColor = '#F36F50'
      break
    case '/contact':
      title = 'Contact'
      themeColor = '#6B3292'
      break
    default:
      themeColor = '#be3365'
      break
  }
  metaTitle.textContent = `${title ? `${title} | ` : ''}Tokita.dev`
  metaThemeColor.setAttribute('content', themeColor)
}

export default handleMeta

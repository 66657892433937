import React, {useEffect, useRef} from 'react'
import Draggable from 'react-draggable'
import imgProfile0 from "./img/profile-0.jpg"
import imgProfile1 from "./img/profile-1.jpg"
import imgProfile2 from "./img/profile-2.jpg"

const PortCircle = () => {
  const portCircle = useRef(null)
  const profileImg = useRef(null)
  useEffect(() => {
    const profileImgArr = [imgProfile0, imgProfile1, imgProfile2]
    const index = Math.floor(Math.random() * 3)
    profileImg.current = profileImgArr[index]
  })
  return (
    <div className='about-port__circle-wrapper'>
      <Draggable
        onStart={() => {
          const light = document.querySelector('.about-port__light-wrapper')
          light.style.opacity = '1'
          const balloon = document.querySelector('.about-port__circle__balloon')
          balloon.classList.remove('is-balloon-active-pc')
          balloon.classList.remove('is-balloon-active-sp')
        }}
      >
        <div className='about-port__circle' ref={portCircle}>
          <div className='about-port__circle__balloon is-balloon-active-pc'>Take the flashlight for your convenience!</div>
          <div className='about-port__circle__backside'/>
          <div className='about-port__circle__front'>
            <div className='about-port__circle__img'>
              <img src={profileImg.current} alt='Me' draggable='false'/>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default PortCircle

import { Shape, Stage, Ticker } from '@createjs/easeljs'

class Particle {
  constructor(id, siblingId) {
    this.ele = document.getElementById(id)
    this.siblingEle = document.getElementById(siblingId)
    this.setSize()
    this.stage = new Stage(id)
    this.particles = []
    this.colorStore = ['#4B3A94', '#852B90', '#F43B3B', '#F3AB69']
    this.maxLife = 100
    this.listener = null
    this.isTickerOn = false
    this.handleTick = this.handleTick.bind(this)
  }

  setSize() {
    this.ele.setAttribute('width', this.ele.parentNode.clientWidth)
    this.ele.setAttribute('height', this.ele.parentNode.clientHeight)
  }

  handleResize() {
    this.stage.canvas.width = this.ele.parentNode.clientWidth
    this.stage.canvas.height = this.ele.parentNode.clientHeight
    this.stage.update()
  }

  init() {
    Ticker.framerate = 60
    this.ele.addEventListener('click', () => {
      this.tickActivate()
    })
    this.siblingEle.addEventListener('click', () => {
      this.tickActivate()
    })
    window.addEventListener('resize', () => {
      this.handleResize()
    })
  }

  tickActivate() {
    if (this.isTickerOn) return
    this.isTickerOn = true
    this.emitParticles()
    this.listener = Ticker.addEventListener('tick', this.handleTick)
  }

  handleTick() {
    this.updateParticles()
    this.stage.update()
  }

  emitParticles() {
    for (let i = 0; i < 20; i++) {
      // オブジェクトの作成
      const particle = new Shape()
      const color = this.colorStore[Math.floor(Math.random() * 4)]
      particle.graphics
        .beginFill(color)
        .drawCircle(0, 0, 30 * Math.random())
      this.stage.addChild(particle)

      // パーティクルの発生場所
      particle.x = this.stage.mouseX
      particle.y = this.stage.mouseY
      // 速度
      particle.vx = 30 * (Math.random() - 0.5)
      particle.vy = 30 * (Math.random() - 0.5)
      // 寿命
      particle.life = this.maxLife
      this.particles.push(particle)
    }
  }

  updateParticles() {
    for (let i = 0; i < this.particles.length; i++) {
      const particle = this.particles[i]
      // 重力
      particle.vy += 1
      // 摩擦
      particle.vx *= 0.96
      particle.vy *= 0.96
      // 速度を位置に適用
      particle.x += particle.vx
      particle.y += particle.vy
      // 地面
      if (particle.y > this.stage.canvas.height) {
        particle.y = this.stage.canvas.height // 行き過ぎ補正
        particle.vy *= -1 // Y軸の速度を反転
      }
      // パーティクルのサイズをライフ依存にする
      const scale = particle.life / this.maxLife
      particle.scaleX = particle.scaleY = scale
      // 寿命を減らす
      particle.life -= 1
      // 寿命の判定
      if (particle.life <= 0) {
        // ステージから削除
        this.stage.removeChild(particle)
        // 配列からも削除
        this.particles.splice(i, 1)
      }
      if (this.particles.length === 0) {
        Ticker.removeEventListener('tick', this.listener)
        this.isTickerOn = false
      }
    }
  }
}

export default Particle
